import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  // PipeTransform,
  // Pipe,
  // Input,
  ViewChild,
  // ViewEncapsulation,
} from "@angular/core";

import { UntypedFormControl, Validators } from "@angular/forms";
import { Customer } from "../models/customer";
import { CustomerService } from "../services/customer.service";
import { Router, ActivatedRoute } from "@angular/router";
import { WorkorderService } from "../services/workorder.service";
import { MatDialog } from "@angular/material/dialog";
import { EnrollmentService } from "../services/enrollment.service";
import Swal from "sweetalert2";
// import { Title } from "@angular/platform-browser";
import { MatAccordion } from "@angular/material/expansion";
import { CanceldeactivateDialogComponent } from "../util/canceldeactivate-dialog/canceldeactivate-dialog.component";
import { UploadDialogComponent } from "../util/upload-dialog/upload-dialog.component";
import { ViewdocsDialogComponent } from "../util/viewdocs-dialog/viewdocs-dialog.component";
import { WorkorderExceptionsComponent } from "../workorder-exceptions/workorder-exceptions.component";
import { UtilityService } from "../services/utility.service";
import { CancelscheduleDialogComponent } from "../util/cancelschedule-dialog/cancelschedule-dialog.component";
import { DataTransferService } from "../services/data-transfer.service";
import { WorkorderStatusDialogComponent } from "../util/workorder-status-dialog/workorder-status-dialog.component";
import { LocationsearchService } from "../services/locationsearch.service";
import { Interaction } from "src/app/models/interaction";
import { Subscription, interval, iif } from "rxjs";
import { InteractionsService } from "src/app/services/interactions.service";
import { MatCheckbox } from "@angular/material/checkbox";
import { OnholdreasonDialogComponent } from "../util/onholdreason-dialog/onholdreason-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { KeyValue, ViewportScroller } from "@angular/common";
import moment from "moment";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ComparisonScreenDialogComponent } from "../util/comparison-screen-dialog/comparison-screen-dialog.component";
import { HomeAuditMeasureComponent } from "../home-audit-measure/home-audit-measure.component";
import { MeasuresComponent } from "../measures/measures.component";
import { UploadExcelComponent } from "../distributor/dialogs/upload-excel/upload-excel.component";
import { QhecReport } from "./qhec-report";
import { UserService } from "../services/user.service";

export interface Dropdown {
  id: string;
  value: string;
}

@Component({
  selector: "app-dr-workorder",
  templateUrl: "./workorder-getsave.component.html",
  styleUrls: ["./workorder-getsave.component.scss"],
})
export class WorkorderGetSaveComponent implements OnInit {
  sectionName: string;
  isDisabled: boolean;
  constructor(
    private customerService: CustomerService,
    private router: Router,
    private workorderService: WorkorderService,
    private enrollmentService: EnrollmentService,
    private utilityService: UtilityService,
    private dialog: MatDialog,
    private locationSearchService: LocationsearchService,
    private dataTransfer: DataTransferService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private interactionService: InteractionsService,
    private scroller: ViewportScroller,
    private userService: UserService
  ) {
    this.className = localStorage.getItem("themeName");
    //----Start Date and End date--------------

    this.minDateToFinish.subscribe((r) => {
      let start = new Date(r);
      this.minDate = start;
    });
  }
  loggedinuserrole: any = this.utilityService.currentUtility.LoggedInUserRole;
  office_id: any = this.utilityService.currentUtility.OfficeId;
  response: any = [];
  loadDialog: any = true;
  loadTable: any = true;
  loadWorkorder: any = true;
  workorderID: any = this.workorderService.currentWorkorderID;
  enrollmentID: any = this.enrollmentService.currentEnrollmentID;
  SectionId1 = "ManufModelData";
  expandedItem: any;
  sectionEdited: any;
  ifExpanded = true;
  sectionObj: SectionArray[] = [];
  newApplianceObj: any;
  dynamicMenuList: any = [];
  group: any;
  subgroup: any;
  subGroup1: any;
  selQaWorkOrder: any;
  showAddInteractions = false;
  showAddMeasures = false;
  compassJobId: any;
  compassEnabled: any;
  user: any;
  count = 0;

  clickCount() {}
  @ViewChild("Woaccordion", { static: false }) Woaccordion: MatAccordion;

  //add interaction
  ifSave: boolean;
  types: Dropdown[] = [];
  marketing: Dropdown[] = [];
  whys: Dropdown[] = [];
  reasons: Dropdown[] = [];
  selectedTypeCode: any;
  selectedReason: any;
  selectedWhy: any;
  selectedMarketing: any;
  isCompleteChecked: boolean;
  notesInput: any;
  timerMinutes: any;
  timerSeconds: any;
  customerId: any;
  officeId: any;
  programSponsorId: any;
  savedInteraction: Interaction = new Interaction();
  startTime = new Date();
  timerInterval: Subscription;
  timersource = interval(1000);
  timer = "0:00";
  statusMessage: string;
  checkIfComplete: boolean = false;
  className: any;
  adding = false;
  disableMainSave = false;
  psegdisable = "No";
  tabularLength: any;
  tabularLengthDelete: any;
  @ViewChild("completeCheckbox", { static: true })
  private completeCheckbox: MatCheckbox;

  //---------Measures-----------
  lineItemId: any;
  measureResponse: any = [];
  measuresLength = 0;
  selectedMeasureType: any;
  term: any;
  measureData: any = [];
  filterNewFixture: any = [];
  existingFixtureArray: any = [];
  esmodelArray: any = [];
  manufacturerArray: any = [];
  energyStarModelArray: any = [];

  manufacturerhvacArray: any = [];
  modelhvacArray: any = [];
  //---------Date-------------------
  thresHoldValue: any;
  startdate = new Date();
  minDateToFinish = new Subject<string>();
  minDate;
  maxDate;
  inputReadOnly = true;
  //---Auto save----
  isChildCompoCalled = false;
  @ViewChild(HomeAuditMeasureComponent) homeAuditComponent;
  @ViewChild(MeasuresComponent) measurComponent;

  initfunc() {
    this.dataTransfer.myMethod(true);
    this.expandedItem = null;
    /* this.loadDRWorkorder(); */
    this.loadTable = false;
    this.locationSearchService.personcompany = null;
    this.locationSearchService.schdate = null;
    this.locationSearchService.scheduledate = null;
    this.locationSearchService.grid = null;
    this.locationSearchService.workorderID = null;
    /*    this.route.params.subscribe(params => {
         if (params["WorkorderId"]) {
           this.group = params["category"];
           this.subgroup = params["subCategory"];
           this.selQaWorkOrder = params["WorkorderId"];
         }
       }); */

    this.workorderID = this.route.snapshot.paramMap.get("workorderID");
    this.group = this.route.snapshot.paramMap.get("group");
    this.subgroup = this.route.snapshot.paramMap.get("subgroup");
    this.subGroup1 = this.route.snapshot.paramMap.get("subgroup1");
  }

  ngOnInit() {
    this.initfunc();
    this.user = this.userService.dbBBCSId === '' ? this.utilityService.currentUtility.BBCSUserId : this.userService.dbBBCSId;
    this.compassEnabled = localStorage.getItem("compassEnabled");
    this.compassJobId = localStorage.getItem("compassJobId");

    //to calculate the number of records in Manufactorers and Models
    this.workorderService
      .addPsgAppliance(
        this.workorderID,
        this.SectionId1,
        this.subgroup,
        this.subGroup1
      )
      .subscribe((res) => {
        this.response.SectionInfo?.forEach((element) => {
          if (this.SectionId1 == element.SectionId) {
            this.tabularLengthDelete = element.Tabular.length;
            element.Tabular.push(res);
          }
        });
      });
  }

  ngAfterViewInit() {
    this.loadDRWorkorder();
    this.loadDynamicMenu();
  }

  loadDynamicMenu() {
    this.workorderID = this.workorderService.currentWorkorderID;
    this.workorderService.loadDynamicMenu(this.workorderID).subscribe((res) => {
      this.dynamicMenuList = res.MenuList;
    });
  }

  chooseRoute(menuId, lineItemID = null) {
    if (menuId == "UploadImage") {
      this.uploadDocs(lineItemID);
    } else if (menuId == "ViewImage") {
      this.viewDocs(lineItemID);
    } else if (menuId == "Deactivate") {
      this.actionWorkorder(this.workorderID);
    } else if (menuId == "Cancel") {
      this.actionWorkorder(this.workorderID);
    } else if (menuId == "Schedule") {
      if (
        this.group != null &&
        this.group != undefined &&
        this.subgroup != null &&
        this.subgroup != undefined
      ) {
        this.workorderService.workorderStatusCode = "schedule";
        this.router.navigate([
          "scheduling",
          this.group,
          this.subgroup,
          this.workorderID,
          "schedule",
        ]);
      }
    } else if (menuId == "Reschedule") {
      if (
        this.group != null &&
        this.group != undefined &&
        this.subgroup != null &&
        this.subgroup != undefined
      ) {
        this.workorderService.workorderStatusCode = "reschedule";
        this.router.navigate([
          "scheduling",
          this.group,
          this.subgroup,
          this.workorderID,
          "reschedule",
        ]);
      }
    } else if (menuId == "CancelAppointment") {
      let className = localStorage.getItem("themeName");
      const dialogRef = this.dialog.open(CancelscheduleDialogComponent, {
        data: {
          workorderID: this.workorderID,
        },
        panelClass: className,
      });
      dialogRef.afterClosed().subscribe((res) => {
        this.loadDRWorkorder();
        this.loadDynamicMenu();
        this.closeMeasureEditor();
      });
    } else if (menuId == "AddInspWorkorder") {
      this.addInspectionWorkorder(this.workorderID);
    } else if (menuId == "Complete") {
      Swal.fire({
        title: "Are you sure you want to complete the workorder?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.completeWorkorder();
          this.closeMeasureEditor();
        }
      });
    } else if (menuId == "ReturnToDE") {
      Swal.fire({
        title: "Are you sure you want to return workorder to data entry?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.returnDeToWorkorder();
          this.closeMeasureEditor();
        }
      });
    } else if (menuId == "OffHold") {
      Swal.fire({
        title: "Are you sure you want to return workorder to Off Hold?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.returnOffHoldWorkorder();
          this.closeMeasureEditor();
        }
      });
    } else if (menuId == "Assign") {
      Swal.fire({
        title: "Are you sure you want to return workorder to Assigned?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.returnAssignWorkorder();
          this.closeMeasureEditor();
        }
      });
    } else if (menuId == "OnHold") {
      // Swal.fire({
      //   title: 'Are you sure you want to return workorder to On Hold?',
      //   text: "You won't be able to revert this!",
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes!'
      // }).then(() => {
      //   this.goToOnHoldReasons(this.workorderID);
      // })
      this.goToOnHoldReasons(this.workorderID);
    } else if (menuId == "InstallComplete") {
      Swal.fire({
        title: "Are you sure you want to mark workorder to Install Complete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.InstallcompleteWorkorder();
          this.closeMeasureEditor();
        }
      });
        } else if (menuId == "Approve") {
      Swal.fire({
        title: "Are you sure you want to mark workorder to Ready for Rebate?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          this.spinner.show();
          this.workorderService
            .changeWorkorderStatus(this.workorderID, this.subgroup)
            .subscribe((res) => {
              if (res?.Action) {
                Swal.fire(res.Message);
              } else {
                this.spinner.hide();
                this.loadDRWorkorder();
                this.closeMeasureEditor();
              }
            });
        }
      });
    } else if (menuId == "Reject") {
      this.actionWorkorder("Reject");
    }
  }

  closeMeasureEditor() {
    this.measuresLength = 0;
    if (this.subGroup1 == "HOMEAUDIT") {
      this.homeAuditComponent?.addMeasureClose();
    }
    else {
      this.measurComponent?.addMeasureClose();
    }
  }
   
  InstallcompleteWorkorder() {
    this.workorderService
      .InstallcompleteWorkorder(this.workorderID)
      .subscribe((res: any) => {
        if (res?.Action == 0) {
          this.spinner.hide();
          Swal.fire({
            text: "Workorder Marked Install Complete!",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            this.loadDRWorkorder();
            this.loadDynamicMenu();
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.Message,
            icon: "error",
            confirmButtonText: "OK",
          }).then((result) => {
            this.loadDRWorkorder();
            this.loadDynamicMenu();
          });
        }
      });
  }

  goToOnHoldReasons(workorderId) {
    let deactivateObj = {
      WorkorderID: workorderId,
      StatusCode: 850,
    };
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(OnholdreasonDialogComponent, {
      data: {
        dataKey: deactivateObj,
      },
      panelClass: className,
    });
    dialogRef.afterClosed().subscribe((res) => {
      this.loadDRWorkorder();
      this.loadDynamicMenu();
      this.closeMeasureEditor();
    });
  }

  onexception(resworkorder) {
    let className = localStorage.getItem("themeName");
    const dialogRefs = this.dialog.open(WorkorderExceptionsComponent, {
      width: "1600px",
      height: "400px",
      data: {
        workorder: resworkorder,
        roles: this.loggedinuserrole,
        office: this.office_id,
        loadDR: this.loadDRWorkorder,
        loadMenu: this.loadDynamicMenu,
      },
      panelClass: className,
    });
    dialogRefs.afterClosed().subscribe((res) => {
      this.loadDRWorkorder();
      this.loadDynamicMenu();
    });
  }

  returnOffHoldWorkorder() {
    this.workorderService
      .returnOffholdWorkorderService(this.workorderID)
      .subscribe((res: any) => {
        //Compass integration -start
        if (res?.Action == 0 && this.compassEnabled == "1") {
          this.workorderService
            .getCompassOffHold(this.workorderID, this.user, this.compassJobId)
            .subscribe((res1) => {
              if (res1.Action) {
                this.spinner.hide();
                Swal.fire({
                  text: " Offhold  not successful. Reason: " + res1.Message,
                  icon: "error",
                  confirmButtonText: "OK",
                }).then((result) => {
                  if (result.value) {
                    this.dialog.closeAll();
                  }
                });
              } else {
                this.spinner.hide();
                Swal.fire({
                  text: "Workorder returned to Off Hold",
                  icon: "success",
                  confirmButtonText: "OK",
                }).then((result) => {
                  if (result.value) {
                    //this.dialog.closeAll();
                    this.loadDRWorkorder();
                    this.loadDynamicMenu();
                  }
                });
              }
            });
        }
        if (res?.Action == 0 && this.compassEnabled == "0") {
          this.spinner.hide();
          Swal.fire({
            text: "Workorder returned to Off Hold successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              //this.dialog.closeAll();
              this.loadDRWorkorder();
              this.loadDynamicMenu();
            }
          });
        }

        if (res?.Action == 0 && this.compassEnabled == "undefined") {
          this.spinner.hide();
          Swal.fire({
            text: "Workorder returned to Off Hold successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              //this.dialog.closeAll();
              this.loadDRWorkorder();
              this.loadDynamicMenu();
            }
          });
        }
        ////Compass integration -end
        if (res?.Action != "0" && this.compassEnabled == "0") {
          this.spinner.hide();
          Swal.fire({
            text: res.Message,
            icon: "error",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.loadDRWorkorder();
              this.loadDynamicMenu();
            }
          });
        }
      });
  }

  returnAssignWorkorder() {
    this.workorderService
      .returnAssignWorkorderService(this.workorderID)
      .subscribe((res: any) => {
        if (res.Action == 0) {
          this.spinner.hide();
          Swal.fire({
            text: "Workorder returned to Assigned",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.loadDRWorkorder();
              this.loadDynamicMenu();
            }
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.Message,
            icon: "error",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.loadDRWorkorder();
              this.loadDynamicMenu();
            }
          });
        }
      });
  }

  returnDeToWorkorder() {
    this.workorderService
      .returnToDEWorkorderService(this.workorderID)
      .subscribe((res: any) => {
        if (res?.Action == 0) {
          this.spinner.hide();
          Swal.fire({
            text: "Workorder returned to data entry",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.loadDRWorkorder();
              this.loadDynamicMenu();
            }
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.Message,
            icon: "error",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.loadDRWorkorder();
              this.loadDynamicMenu();
            }
          });
        }
      });
  }

  completeWorkorder() {
    this.workorderService
      .completeWorkorder(this.workorderID)
      .subscribe((res: any) => {
        if (res?.Action == 0) {
          this.spinner.hide();
          Swal.fire({
            text: "Workorder Marked complete!",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.loadDRWorkorder();
              this.loadDynamicMenu();
            }
          });
        } else {
          this.spinner.hide();
          Swal.fire({
            text: res.Message,
            icon: "error",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.loadDRWorkorder();
              this.loadDynamicMenu();
              this.closeMeasureEditor();
            }
          });
        }
      });
  }

  oninitialize(selectvalue: any, attribute: any, editable: any) {
    if (attribute == "REFOWN1") {
      if (selectvalue == "2" && editable == "Yes") {
        this.psegdisable = "Yes";
      } else if (selectvalue == "1" || editable != "Yes") {
        this.psegdisable = "No";
      }
    }
  }

  dataChanged_customize($event, sectionId, rowId, attribute: any) {
    if (attribute == "REFOWN1") {
      this.onchangedropdown($event);
    }
    this.sectionEdited = true;
    this.sectionObj.push({ sectionId: sectionId, rowId: rowId });
  }

  dataChanged($event, sectionId, rowId) {
    this.sectionEdited = true;
    this.sectionObj.push({ sectionId: sectionId, rowId: rowId });
  }

  onchangedropdown(value: any) {
    if (value == "2") {
      this.psegdisable = "Yes";
    } else if (value == "1") {
      this.psegdisable = "No";
    }
  }

  psgDataChanged($event, sectionId, rIndex, cIndex, Name, nIndex) {
    let index = nIndex ? nIndex : rIndex;
    this.response.SectionInfo.forEach((element) => {
      if (sectionId == element.SectionId) {
        element.IsSectionModified = "true";
        element.Tabular[index].showAddMeasures = 1;
        element.Tabular[index].IsLineitemModified = 1;
        element.Tabular[index].Attributes[cIndex]["SelectedValue"] = $event;
        const selValList =
          element.Tabular[index].Attributes[cIndex]["Values"] || [];
        /*
        if (selValList.length > 0) {
          selValList.forEach((val) => {
            if (val.Value == $event) {
              element.Tabular[index].LineitemMasterId = val.Id;
            }
          });
        }
        */
      }
    });
  }
  /* old code

  addPSGAppliance(index, SectionId) {
    this.expandedItem = index;
    this.workorderService
      .addPsgAppliance(this.workorderID, SectionId)
      .subscribe(
        (res) => {
          this.response.SectionInfo.forEach((element) => {
            if (SectionId == element.SectionId) {
              element.IsSectionModified = "true";
              res["newIndex"] = element.Tabular.length;
              element.Tabular.push(res);
            }
          });
        },
        (err) => {}
      );
  }
*/

  addPSGAppliance(index, SectionId) {
    this.expandedItem = index;
    this.subgroup = localStorage.getItem("subGroup");
    this.subGroup1 = localStorage.getItem("subGroup1");
    this.workorderService
      .addPsgAppliance(
        this.workorderID,
        SectionId,
        this.subgroup,
        this.subGroup1
      )
      .subscribe(
        (res) => {
          this.response.SectionInfo.forEach((element) => {
            if (SectionId == element.SectionId) {
              element.IsSectionModified = "true";
              res["newIndex"] = element.Tabular.length;
              this.tabularLength = element.Tabular.length;
              if (this.tabularLength == 10) {
                Swal.fire({
                  text: "You cannot add more than 10 Manufacturers and Models",
                  icon: "error",
                  confirmButtonText: "OK",
                }).then(() => {
                  this.dialog.closeAll();
                  this.loadDRWorkorder();
                  this.loadDynamicMenu();
                });
              }
              element.Tabular.push(res);
            }
          });
        },
        (err) => {}
      );
  }

  addApplianceInfo(index, SectionId) {
    this.expandedItem = index;
    this.subgroup = localStorage.getItem("subGroup");
    this.subGroup1 = localStorage.getItem("subGroup1");
    if (this.tabularLength > 10) {
      Swal.fire({
        text: "You cannot add more than 10 Manufacturers and Models",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        this.dialog.closeAll();
        this.loadDRWorkorder();
        this.loadDynamicMenu();
      });
    }
    this.workorderService
      .addApplianceDetails(
        this.workorderID,
        SectionId,
        this.subgroup,
        this.subGroup1
      )
      .subscribe(
        (res) => {
          this.response.SectionInfo.forEach((element) => {
            if (SectionId == element.SectionId) {
              element.IsSectionModified = "true";
              res["newIndex"] = element.Tabular.length;
              if (this.tabularLength == 10) {
                Swal.fire({
                  text: "You cannot add more than 10 Manufacturers and Models",
                  icon: "error",
                  confirmButtonText: "OK",
                }).then(() => {
                  this.dialog.closeAll();
                  this.loadDRWorkorder();
                  this.loadDynamicMenu();
                });
              }
              element.Tabular.push(res);
            }
          });
        },
        (err) => {}
      );
  }

  onDeletePSGAppliance(lineItem, oldIndex, newIndex, sectionId) {
    let index = newIndex ? newIndex : oldIndex;
    this.subgroup = localStorage.getItem("subGroup");
    this.subGroup1 = localStorage.getItem("subGroup1");
    if (lineItem) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result: any) => {
        // if line item is old then call delete API.
        if (result.value) {
          this.workorderService
            .deletePSGRow(this.response.WorkorderId, lineItem, this.subgroup)
            .subscribe(
              (res) => {
                if (!res.Message) {
                  Swal.fire({
                    text: "Successfully Deleted",
                    icon: "success",
                    confirmButtonText: "OK",
                  }).then(() => {
                    this.dialog.closeAll();
                    this.loadDRWorkorder();
                    this.loadDynamicMenu();
                  });
                } else {
                  Swal.fire({
                    text: res.Message,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                }
              },
              (err) => {
                Swal.fire({
                  text: "Error While Deleting",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            );
        }
      });
    } else {
      // if line item is new delete from the local variable.
      this.response.SectionInfo.forEach((element) => {
        if (sectionId == element.SectionId) {
          element.Tabular.splice(index, 1);
        }
      });
    }
  }

  closeAll() {
    if (this.ifExpanded) {
      this.ifExpanded = false;
      this.Woaccordion.closeAll();
    } else {
      this.Woaccordion.openAll();
      this.ifExpanded = true;
    }
  }

  deactivateWorkorder(workorderId) {
    let deactivateObj = {
      WorkorderID: workorderId,
      StatusCode: 920,
      DialogTitle: "Deactivate Workorder",
    };
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(CanceldeactivateDialogComponent, {
      data: {
        dataKey: deactivateObj,
      },
      panelClass: className,
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.loadDRWorkorder();
      this.loadDynamicMenu();
    });
  }

  uploadDocs(lineItemID = null) {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      data: {
        workOrderID: this.workorderID,
        lineItemID: lineItemID,
      },
      panelClass: className,
    });

    if (lineItemID != null) {
      dialogRef.afterClosed().subscribe((res) => {
        this.loadDRWorkorder();
        this.loadDynamicMenu();
        this.closeMeasureEditor();
      });
    }
  }

  viewDocs(lineItemID) {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(ViewdocsDialogComponent, {
      data: { workOrderID: this.workorderID, lineItemID: lineItemID },
      panelClass: className,
    });
  }

  loadDRWorkorder() {
    //this.workorderID = this.workorderService.currentWorkorderID;
    this.enrollmentID = this.enrollmentService?.currentEnrollmentID;
    this.loadWorkorder = true;

    if (this.selQaWorkOrder) {
      this.workorderService
        .getQaWorkorder(this.selQaWorkOrder)
        .subscribe((res) => {
          this.response = res;
          this.loadWorkorder = false;
        });
    } else {
      var customerId = "undefined";
      if (this.workorderService?.currentWorkorder != undefined) {
        customerId = this.workorderService?.currentWorkorder?.CustomerId;
      }
      this.workorderService
        .getBBCSWorkorderDetail(
          this.workorderID,
          this.group,
          this.subgroup,
          customerId,
          this.subGroup1
        )
        .subscribe((res) => {
          this.response = res;
          // console.log(this.response, null, 2);
		      if (this.response.GenerateReport == 1) {
            this.doReport();
          }			
          this.response["MeasureSectionInfo"] = [];
          const measuredata = localStorage.getItem("mData");
          if (measuredata != null) localStorage.removeItem("mData");
          this.workorderService.currentIAWorkorderID =
            this.response?.IAWorkorder;
          localStorage.setItem("compassEnabled", this.response.CompassEnabled);
          localStorage.setItem("compassJobId", this.response.CompassJobId);
          localStorage.setItem("subGroup", this.response.SubGroup ?? "ALL");
          localStorage.setItem("subGroup1", this.response.SubGroup1 ?? "ALL");
          localStorage.setItem(
            "programdetailid",
            this.response.ProgramdetailId
          );
          this.loadWorkorder = false;
          this.datepickerWork(res, false);
          this.response?.SectionInfo.forEach((element) => {
            if (element.DetailedInfo) {
              element.DetailedInfo.forEach((dElement) => {
                dElement.Attributes.forEach((aElement) => {
                  if (aElement.DataType == 'Bit') {
                    aElement.SelectedValue = (aElement.SelectedValue == true) ? 1 : 0;
                  }
                  if (aElement.AttributeId == 'TVFD') {
                    this.thresHoldValue = aElement.SelectedValue;
                  }
                });
              });
            }
          });
          if (
            this.response.SubGroup1 == "COMLIGHT" ||
            this.response.SubGroup1 == "PRISCRIP"
          ) {
            this.payeeOnPageLoad();
          }
        });
    }
  }

  openAddInteraction(customerId, toggleAddInteractions, sectionName, index) {
    this.expandedItem = index;
    this.loadDialog = true;
    this.showAddInteractions =
      toggleAddInteractions == 1 && sectionName == "Interactions";
    this.customerService
      .getCustomerById(customerId)
      .subscribe((cust: Customer[]) => {
        this.getCustomerObj(cust);
        this.onLoad();
      });
  }
  // for unsorted headers
  unsorted() {}

  addAppliance(tile, index) {
    this.expandedItem = index;
    this.workorderService
      .addAppliance(this.workorderID, this.subgroup)
      .subscribe(
        (res) => {
          this.newApplianceObj = res;
          tile.DetailedInfo.push(this.newApplianceObj.DetailedInfo);
        },
        (err) => {}
      );
  }

  getCustomerObj(cust) {
    let className = localStorage.getItem("themeName");
    this.customerService.currentCustomer = cust;
    //const dialogRef = this.dialog.open(AddinteractionDialogComponent, { panelClass: className });
    this.loadDialog = false;

    /* this.loadDRWorkorder(); */
    // this.loadTable = true;
    // this.workorderService.getBBCSWorkorderDetail(this.workorderID, this.group, this.subgroup, this.customerService.currentCustomer.Id).subscribe(res => {
    //   this.response = res;
    //   this.loadTable = false;
    // });

    // dialogRef.afterClosed().subscribe(result => {

    // });
  }

  accordionOpen(i) {
    this.expandedItem = i;
  }

  customerClick(customerId) {
    this.loadWorkorder = true;
    this.customerService
      .getCustomerById(customerId)
      .subscribe((cust: Customer[]) => {
        this.navigateToCustomer(cust);
      });

    return false;
  }

  navigateToCustomer(cust) {
    const tempcust: Customer = new Customer();
    tempcust.setUpFromWS(cust);
    this.customerService.currentCustomer = tempcust;
    this.customerService.storedCustomers(tempcust);
    this.loadWorkorder = false;
    this.router.navigate(["/detail"]);
  }

  onDeleteAppliance(additionalInfo, workorderID) {
    let controlDeviceId = "";
    let drcontrolId = "";
    let self = this;
    additionalInfo.forEach((element) => {
      if (element.Id == "controlleddevice_id") {
        controlDeviceId = element.Value;
      }
      if (element.Id == "drcontrol_id") {
        drcontrolId = element.Value;
      }
    });

    let additionalInfoBody = {
      ControlledDeviceId: controlDeviceId,
      DrControlId: drcontrolId,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(() => {
      this.confirmDelete(workorderID, additionalInfoBody);
    });
  }

  confirmDelete(workorderID, additionalInfoBody) {
    let self = this;
    this.workorderService
      .deleteWorkorderDR(workorderID, additionalInfoBody)
      .subscribe(
        (res) => {
          Swal.fire({
            text: "Appliance deleted successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            self.dialog.closeAll();
            this.workorderService
              .getBBCSWorkorderDetail(
                this.workorderID,
                this.group,
                this.subgroup,
                this.customerService.currentCustomer.Id,
                this.subGroup1
              )
              .subscribe((res) => {
                this.response = res;
              });
          });
        },
        (err) => {
          Swal.fire({
            text: "Appliance could not be deleted",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            self.dialog.closeAll();
            this.workorderService
              .getBBCSWorkorderDetail(
                this.workorderID,
                this.group,
                this.subgroup,
                this.customerService.currentCustomer.Id,
                this.subGroup1
              )
              .subscribe((res) => {
                this.response = res;
              });
          });
        }
      );
  }

  onSave(resp, measureResponse = null) {
    if (!this.isChildCompoCalled && measureResponse == null) {
      measureResponse = JSON.parse(localStorage.getItem("mData"));
      this.isChildCompoCalled = true;
    }
    resp["MeasureSectionInfo"] = [];
    resp.MeasureSectionInfo?.push(measureResponse);
    this.spinner.show();
    this.datepickerWork(resp, true);
    this.disableMainSave = true;
    let selUtilPId = localStorage.getItem("selectedUtilPId");
    if (selUtilPId == "PSG1") {
      let copyResp = resp;
      copyResp.SectionInfo.forEach((element) => {
        if (element.IsSectionModified == "true") {
          element.Tabular.forEach((item) => {
            delete item.newIndex;
          });
        }
      });
      this.workorderService
        .saveWorkorder(
          copyResp,
          copyResp.WorkorderId,
          this.subgroup,
          this.subGroup1
        )
        .subscribe(
          (res) => {
            this.disableMainSave = false;
            if (!res.Message) {
              this.spinner.hide();
              Swal.fire({
                text: "Successfully Saved",
                icon: "success",
                confirmButtonText: "OK",
              }).then(() => {
                this.dialog.closeAll();
                this.loadDRWorkorder();
                this.loadDynamicMenu();
              });
            } else {
              this.spinner.hide();
              Swal.fire({
                text: res.Message,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          },
          (err) => {
            this.disableMainSave = false;
            this.spinner.hide();
            Swal.fire({
              text: "Error saving workorder",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        );
    } else {
      if (this.sectionEdited) {
        this.sectionObj.forEach((element) => {
          if (
            resp.SectionInfo[element.sectionId].IsSectionModified == "false"
          ) {
            resp.SectionInfo[element.sectionId].IsSectionModified = "true";
          }
          if (element.rowId != undefined || element.rowId != null) {
            if (
              resp.SectionInfo[element.sectionId].DetailedInfo[element.rowId]
                .IsRowModified == "false"
            ) {
              resp.SectionInfo[element.sectionId].DetailedInfo[
                element.rowId
              ].IsRowModified = "true";
            }
          }
        });
      }

      let copy = JSON.parse(JSON.stringify(resp));
      copy.SectionInfo.forEach((element) => {
        if (element.DetailedInfo) {
          element.DetailedInfo.forEach((dElement) => {
            dElement.Attributes.forEach((aElement) => {
              if (aElement.Values) {
                aElement.Values = [];
              }
              if (aElement.DataType == 'Bit') {
                aElement.SelectedValue = (aElement.SelectedValue == true) ? 1 : 0;
              }
            });
          });
        }
      });

      this.workorderService
        .saveWorkorder(copy, resp.WorkorderId, this.subgroup, this.subGroup1)
        .subscribe((res) => {
          let applianceError = "";
          let accountError = "";
          let matIcon = "";
          this.disableMainSave = false;
         if (this.subgroup == "AUDIT") {
          const msg = res.Message;
          if (msg.indexOf("Confirm") != -1) {
            this.spinner.hide();
            const wrapper = document.createElement("div");
            wrapper.innerHTML = res.Message;
            Swal.fire({
              title: "Workorder Status Change",
              html: wrapper,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
}).then((result) => {
              this.dialog.closeAll();
              if (result.value) {
               
                                  this.workorderService
                                  .changeWorkorderStatus(resp.WorkorderId, this.subgroup)
                                  .subscribe((res) => {
                                    // console.log(res);
                                    if (res?.Action) {
                                      
                                      Swal.fire(res.Message);
                                    } else {
                                      this.loadDRWorkorder();
                                      this.closeMeasureEditor();
                                    }
                                  });
                                }else {
                                    this.loadDRWorkorder();
                  this.loadDynamicMenu();
                  this.closeMeasureEditor();
                                  }
                                })

          }else{
            this.spinner.hide();
            Swal.fire({
              text: res.Message,
              icon: "success",
              timer: 3000,
              showConfirmButton: false,
            }).then(() => {
              this.dialog.closeAll();
              this.loadDRWorkorder();
              this.loadDynamicMenu();
              this.closeMeasureEditor();
            });

          }
         } else {


          if (this.subgroup == "INSTALL") {
            if (res.SaveApplianceInfoError) {
              applianceError = res.SaveApplianceInfoErrorMsg;
              matIcon = "<i class='fa fa-close'></i>";
            } else if (!res.SaveApplianceInfoError) {
              applianceError = "Appliance Info";
              matIcon = "<i class='fa fa-check'></i>";
            }
            if (res.SaveAccountInfoError) {
              matIcon = "<i class='fa fa-close'></i>";
              accountError = res.SaveAccountInfoErrorMsg;
            } else if (!res.SaveAccountInfoError) {
              matIcon = "<i class='fa fa-check'></i>";
              accountError = "Account Info";
            }

            const wrapper = document.createElement("div");
            wrapper.innerHTML =
              accountError + matIcon + applianceError + matIcon;
            this.spinner.hide();
            Swal.fire({
              title: "DR Save Status",
              html: wrapper,
              confirmButtonText: "OK",
            }).then(() => {
              this.dialog.closeAll();
              this.loadDRWorkorder();
              this.loadDynamicMenu();
            });
          } else if (this.subgroup == "QA") {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = res.Message;

            if (res.Action) {
              const msg = res.Message;
              if (msg.indexOf("Confirm") != -1) {
                // its a confirmation to change the status of workorder
                this.spinner.hide();
                Swal.fire({
                  title: "Save Workorder",
                  html: wrapper,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                }).then((result) => {
                  this.dialog.closeAll();

                  if (result.value) {
                    this.workorderService
                      .changeWorkorderStatus(resp.WorkorderId, this.subgroup)
                      .subscribe((res) => {
                        if (res.Action) {
                          Swal.fire(res.Message);
                        } else {
                          this.loadDRWorkorder();
                        }
                      });
                  } else {
                    this.loadDRWorkorder();
                  }
                });
              } else {
                wrapper.innerHTML = res.Message;
                this.spinner.hide();
                this.disableMainSave = false;
                Swal.fire({
                  title: "Save Workorder",
                  html: wrapper,
                  confirmButtonText: "OK",
                }).then(() => {
                  this.dialog.closeAll();
                  this.loadDRWorkorder();
                });
              }
            }
          } else {
            if (res.Action) {
              const qaQcmsg = res.Message;
              if (
                qaQcmsg.indexOf("READY") != -1 ||
                qaQcmsg.indexOf("Ready") != -1
              ) {
                this.spinner.hide();
                Swal.fire({
                  title: "Approve Status Change",
                  text: res.Message,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                }).then((result) => {
                  this.dialog.closeAll();
                  if (result.value) {
                    this.spinner.hide();
                    this.workorderService
                      .changeWorkorderStatus(resp.WorkorderId, this.subgroup)
                      .subscribe((res) => {
                        if (res.Action) {
                          Swal.fire(res.Message);
                        } else {
                          this.loadDRWorkorder();
                          this.closeMeasureEditor();
                        }
                      });
                  } else {
                    this.loadDRWorkorder();
                    this.closeMeasureEditor();
                  }
                });
              } else{
                this.spinner.hide();
                Swal.fire({
                  title: res.Message,
                  icon: "info",
                  confirmButtonText: "OK",
                });
              }
            } else {
              if (!res.Action) {
                this.spinner.hide();
                this.measuresLength = 0;
                Swal.fire({
                  text: res.Message,
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false,
                }).then(() => {
                  this.dialog.closeAll();
                  this.loadDRWorkorder();
                  this.loadDynamicMenu();
                });
              } else {
                this.spinner.hide();
                Swal.fire({
                  title: res.Message,
                  icon: "info",
                  confirmButtonText: "OK",
                });
              }
            }
          }
        }
        });
    }
    this.isChildCompoCalled = false;
  }

  addInspectionWorkorder(workorderID) {
    this.workorderService.addInspectionWorkorder(this.workorderID).subscribe(
      (res) => {
        if (
          (res.WorkorderId != null || res.WorkorderId != "") &&
          this.subgroup != "PREQUALIFY"
        ) {
          Swal.fire({
            text: res.Message + ". Do you want to open the workorder?",
            icon: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
          }).then((result) => {
            if (result.value) {
              this.workorderID = res.WorkorderId;
              this.workorderService
                .getBBCSWorkorderDetail(
                  res.WorkorderId,
                  res.Group,
                  res.SubGroup,
                  "unknown",
                  res.SubGroup1
                )
                .subscribe((res) => {
                  this.response = res;
                  this.loadWorkorder = false;
                });
            }
          });
        } else {
          Swal.fire({
            text: res.Message,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            this.dialog.closeAll();
            this.loadDRWorkorder();
            this.closeMeasureEditor();
          });
        }
      },
      (err) => {
        Swal.fire({
          text: "Error creating inspection workorder",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    );
  }

  getWorkOrders(workId) {
    let className = localStorage.getItem("themeName");
    this.workorderService.getWorkorderData(this.workorderID).subscribe(
      (res) => {
        const dialogRef = this.dialog.open(WorkorderStatusDialogComponent, {
          data: res.StatusHistory,
          panelClass: className,
        });
      },
      (err) => {
        Swal.fire({
          text: "Error",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    );
  }

  // add interaction code
  onLoad() {
    /*  this.ifSave=true; */
    this.timerInterval = this.timersource.subscribe(() =>
      this.interactionTimer()
    );
    this.types = [];
    this.whys = [];
    this.reasons = [];
    this.populateTypeDropdown();
    this.populateMarketingDropdown();
    this.selectedMarketing = "";
    this.notesInput = "";
    this.isCompleteChecked = true;
    if (!this.isCompleteChecked) {
      this.checkIfComplete = false;
    }
    this.checkIfComplete = false;
    this.customerId = this.customerService.currentCustomer.Id;
    this.programSponsorId = this.utilityService.currentUtility.ProgramsponsorId;
    this.officeId = this.utilityService.currentUtility.OfficeId;
    this.startTime = new Date();
    // this.timerInterval = this.timersource.subscribe(() => this.interactionTimer());
    this.adding = false;
    if (this.completeCheckbox) {
      this.completeCheckbox.checked = true;
    }
  }

  onCompleteClick(event) {
    if (event.checked) {
      this.isCompleteChecked = true;
      this.checkIfComplete = true;
      this.selectedReason = "";
      this.completeCheckbox.checked = true;
    } else {
      this.isCompleteChecked = false;
      this.checkIfComplete = false;

      if (
        this.selectedTypeCode != null ||
        this.selectedTypeCode != undefined ||
        this.selectedTypeCode != ""
      ) {
        this.populateReasonDropdown();
      }
      this.completeCheckbox.checked = false;
    }
  }

  //  ngOnDestroy() {
  //      this.timerInterval.unsubscribe();
  //  }

  private interactionTimer() {
    this.timerMinutes = 0;
    this.timerSeconds = 0;
    const elapsed = (new Date().getTime() - this.startTime.getTime()) / 1000; // secs
    const sec = elapsed % 60;
    const min = Math.floor(elapsed / 60);
    this.timerMinutes = min;
    this.timerSeconds = sec;
    this.timer =
      this.timerMinutes +
      ":" +
      (this.timerSeconds < 10 ? "0" : "") +
      this.timerSeconds.toFixed(0);
  }

  private populateTypeDropdown() {
    this.types = [];
    this.interactionService.getInteractionType().subscribe(
      (res) => {
        if (
          res.InteractionTypes != null &&
          Array.isArray(res.InteractionTypes)
        ) {
          res.InteractionTypes.forEach((element) => {
            this.types.push({
              id: element["Name"],
              value: element["Description"],
            });
          });

          this.selectedTypeCode = this.types[0];
          this.changeType(this.selectedTypeCode);
        }
      },
      (err) => {
        Swal.fire({
          text: err,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    );
  }

  checkGoodDropForm(): boolean {
    if (
      this.selectedTypeCode === undefined ||
      this.selectedTypeCode.value === "General Query" ||
      this.selectedWhy === undefined ||
      (!this.isCompleteChecked &&
        (this.selectedReason === undefined || this.selectedReason === ""))
    ) {
      return true;
    } else {
      return false;
    }
  }

  addInteraction() {
    this.adding = true;
    this.customerId = this.customerService.currentCustomer.Id;
    try {
      // start date and end date same
      const doneDate = moment();
      const formattedDoneDate = doneDate.toJSON();

      const endDate = moment();
      const formattedEndDate = endDate.toJSON();

      const startDate = doneDate;
      startDate.subtract(this.timerMinutes, "minutes");
      startDate.subtract(this.timerSeconds, "seconds");
      const formattedStartDate = startDate.toJSON();

      this.savedInteraction.CustomerId =
        this.customerId === null ? "" : this.customerId;
      if (this.selectedMarketing != undefined) {
        this.savedInteraction.MarketingCode =
          this.selectedMarketing.id == null ? "" : this.selectedMarketing.id;
        this.savedInteraction.MarketingDescription =
          this.selectedMarketing.value == null
            ? ""
            : this.selectedMarketing.value;
      }

      this.savedInteraction.Notes =
        this.notesInput === null ? "" : this.notesInput;
      if (this.selectedReason != undefined) {
        this.savedInteraction.ReasonCode =
          this.selectedReason.id === null ? "" : this.selectedReason.id;
        this.savedInteraction.ReasonDescription =
          this.selectedReason.value === null ? "" : this.selectedReason.value;
      }
      if (this.selectedWhy != undefined) {
        this.savedInteraction.WhyCode =
          this.selectedWhy.id === null ? "" : this.selectedWhy.id;
        this.savedInteraction.WhyDescription =
          this.selectedWhy.value === null ? "" : this.selectedWhy.value;
      }
      this.savedInteraction.TypeCode =
        this.selectedTypeCode.id === null ? "" : this.selectedTypeCode.id;
      this.savedInteraction.TypeDescription =
        this.selectedTypeCode.value === null ? "" : this.selectedTypeCode.value;
      this.savedInteraction.IsComplete =
        this.isCompleteChecked === false ? false : this.isCompleteChecked;
      if (this.utilityService.currentProgramSponsor.SourceId === "BBCS") {
        this.savedInteraction.User =
          this.utilityService.currentUtility.BBCSUserId === null
            ? ""
            : this.utilityService.currentUtility.BBCSUserId;
      } else {
        this.savedInteraction.User =
          localStorage.getItem("loggedInUserName") === null
            ? ""
            : localStorage.getItem("loggedInUserName");
      }

      this.savedInteraction.StartDate = new Date(formattedStartDate).toJSON();
      this.savedInteraction.EndDate = new Date(formattedEndDate).toJSON();
      this.savedInteraction.DoneDate = new Date(formattedDoneDate).toJSON();
      this.savedInteraction.WorkorderId =
        this.workorderService.currentWorkorderID == null ||
        this.workorderService.currentWorkorderID == undefined
          ? null
          : this.workorderService.currentWorkorderID;
      this.savedInteraction.EnrollmentId =
        this.enrollmentService.currentEnrollmentID == null ||
        this.enrollmentService.currentEnrollmentID == undefined
          ? null
          : this.enrollmentService.currentEnrollmentID;

      this.savedInteraction.ProgramSponsorId =
        this.programSponsorId === null ? "" : this.programSponsorId;
      if (this.savedInteraction != null) {
        this.ifSave = false;
        this.interactionService
          .addInteractions(this.savedInteraction)
          .subscribe(
            (res) => {
              try {
                this.ifSave = true;
                this.loadTable = false;
                this.showAddInteractions = false;
                this.timerInterval.unsubscribe();
                //this.initfunc();
                this.loadDRWorkorder();
                this.loadDynamicMenu();
                Swal.fire({
                  text: "Saved Successfully!",
                  icon: "success",
                  confirmButtonText: "OK",
                }).then((result) => {
                  this.dialog.closeAll();
                  if (result.value) {
                    this.onLoad();
                  }
                });
              } catch {
                Swal.fire({
                  text: "Server side error! Please try again",
                  icon: "error",
                  confirmButtonText: "OK",
                }).then((result) => {
                  this.dialog.closeAll();
                  if (result.value) {
                    this.onLoad();
                  }
                });
              }
            },
            (err) => {
              this.dialog.closeAll();
              Swal.fire({
                text: err,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          );
      }
    } catch {
      Swal.fire({
        text: "Please check the entered data",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  addInteractionClose() {
    this.showAddInteractions = false;
    // this.timerInterval.unsubscribe();
    this.loadTable = false;
    this.workorderService
      .getBBCSWorkorderDetail(
        this.workorderID,
        this.group,
        this.subgroup,
        this.customerService.currentCustomer.Id,
        this.subGroup1
      )
      .subscribe((res) => {
        this.response = res;
        this.loadTable = false;
      });
  }

  changeType(value) {
    if (value != null && value !== "") {
      this.whys = [];
      this.selectedWhy = undefined;
      this.reasons = [];
      this.populateWhyDropdown();
      if (this.checkIfComplete === false) {
        this.populateReasonDropdown();
      }
    }
  }

  private populateWhyDropdown() {
    this.whys = [];
    this.interactionService
      .getInteractionWhys(this.selectedTypeCode.id)
      .subscribe(
        (res) => {
          if (
            res.InteractionWhys != null &&
            Array.isArray(res.InteractionWhys)
          ) {
            res.InteractionWhys.forEach((element) => {
              this.whys.push({
                id: element["Name"],
                value: element["Description"],
              });
            });
          }
        },
        (err) => {
          Swal.fire({
            text: err,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      );
  }

  private populateReasonDropdown() {
    this.reasons = [];
    this.interactionService
      .getInteractionReasons(this.selectedTypeCode.id)
      .subscribe(
        (res) => {
          if (
            res.InteractionReasons != null &&
            Array.isArray(res.InteractionReasons)
          ) {
            res.InteractionReasons.forEach((element) => {
              this.reasons.push({
                id: element["Name"],
                value: element["Description"],
              });
            });
          }
        },
        (err) => {
          Swal.fire({
            text: err,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      );
  }

  private populateMarketingDropdown() {
    this.marketing = [];
    this.interactionService.getInteractionMarketing().subscribe(
      (res) => {
        if (
          res.InteractionMarketing != null &&
          Array.isArray(res.InteractionMarketing)
        ) {
          res.InteractionMarketing.forEach((element) => {
            this.marketing.push({
              id: element["Name"],
              value: element["Description"],
            });
          });
        }
      },
      (err) => {
        Swal.fire({
          text: err,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    );
  }

  //------------------Measures----------------------
  openAddMeasure(toggleAddMeasure, index, Lineitemmasterid) {
    this.existingFixtureArray = [];
    this.energyStarModelArray = [];
    this.manufacturerArray = [];
    this.modelhvacArray = [];
    this.expandedItem = index;
    this.loadDialog = true;
    this.showAddMeasures = toggleAddMeasure;
    if (this.showAddMeasures == true && Lineitemmasterid != null) {
      this.addMeasures(Lineitemmasterid);
    }
  }

  addMeasures(Lineitemmasterid) {
    this.spinner.show();
    this.measureResponse = [];
    this.measuresLength = 0;
    this.selectedMeasureType = Lineitemmasterid;
    this.workorderService
      .addMeasures(
        this.workorderID,
        "addMeasure",
        localStorage.getItem("subGroup"),
        "ALL",
        Lineitemmasterid ?? "ALL",
        localStorage.getItem("subGroup1")
      )
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.measuresLength = res?.DetailedInfo[0]?.Attributes?.length;
          this.lineItemId = res?.DetailedInfo[0]?.Attributes[0]?.SelectedValue;
          this.measureResponse = res;

          res?.DetailedInfo[0]?.Attributes.forEach(element => {
            if (element.AttributeId == 'repltype_' + this.utilityService.currentUtility.OfficeId.toLowerCase() && element.Name == "New Fixture Type") {
              this.measureData = element.Values;
              this.filterNewFixture = element.Values;
            }
            if (element.AttributeId == 'test_eq_serialno_' + this.utilityService.currentUtility.OfficeId.toLowerCase()) {
              this.measureData = element.Values;
              this.esmodelArray = element.Values;
            }
            if (element.AttributeId == 'replmanufhvac_' + this.utilityService.currentUtility.OfficeId.toLowerCase()) {
              this.measureData = element.Values;
              this.manufacturerhvacArray = element.Values;
            }
          });
        },

        () => {
          const err = "Error in Adding Measures";
        }
      );
  }
  //------------------ End of Measures----------------------

	// --------------- Report Handling -----------------------

  doReport() {
    let repPref = this.response.SectionInfo[2].DetailedInfo[0].Attributes[28].SelectedValue;
    if (repPref == undefined || repPref == '') {
      console.log("Report options not defined");
      return;
    }

    let repPrefIndex = this.response.SectionInfo[2].DetailedInfo[0].Attributes[28].Values.findIndex(val => val.Id == repPref);
    if (repPrefIndex < 0) {
      console.log("Report options not available");
      return;
    }

    let repPrefValue = this.response.SectionInfo[2].DetailedInfo[0].Attributes[28].Values[repPrefIndex].Value;
    if (repPrefValue == 'Decline report ') {
      console.log("Report declined");
      return;
    }

    let emailReport = 0;
    if (repPrefValue == 'Email') {
      emailReport = 1;
    }

    // console.log(">>>> in reports 1 " + repPref + " and repPrefIndex=" + repPrefIndex + " and repPrefValue=" + repPrefValue);
    const reportInstance = new QhecReport();

    let blob = reportInstance.doDoc(this.response);

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let data = fileReader.result;

      let fileName = "QHEC_" + this.response.WorkorderId + ".pdf";
      let body = {
        "File": fileName,
        "ImageCode": 'AUDREPORT',
        "User": this.utilityService.currentUtility.BBCSUserId,
        "EmailReport": emailReport,
        "Content": data.slice(28)
      };

      this.workorderService.uploadFile(fileName, body, this.response.WorkorderId).subscribe((res: any) => {
        // console.log(res, null, 2);
        if (res.Success) {
          // not a critical error because report will be marked for generation each time a complete QHEC wo is viewed
          // if the report does not exist
          console.log(">>>>>>> upload good");
        } else {
          console.log(">>>>>>> upload bad");
        }
      });
    };

    fileReader.readAsDataURL(blob);
  }

  //-----------------Date picker implementation------------
  datepickerWork(resp, isAdd) {
    const sections = resp?.SectionInfo || [];
    sections.forEach(section => {
      const attributes = section?.DetailedInfo?.[0]?.Attributes || [];

      attributes.forEach(attr => {
        if (attr.DataType === 'Date') {
          if (attr.SelectedValue) {
            if (isAdd) {
              if (
                attr.SelectedValue != null ||
                attr.SelectedValue != undefined
              ) {
                let dt = moment(attr.SelectedValue).toLocaleString();
                // console.log("Date " + dt)
                if (dt.indexOf("0530") != -1) {
                  attr.SelectedValue = moment(dt).add(1, "day").toDate();
                  // console.log("if block");
                } else {
                  attr.SelectedValue = moment(dt).toDate();
                  // console.log("else block");
                }
              }
            } else {
              if (
                attr.SelectedValue != null ||
                attr.SelectedValue != undefined
              ) {
                this.inputReadOnly = !this.inputReadOnly;
                attr.SelectedValue = new Date(attr.SelectedValue);
              }
            }
          }
        }
      });
    });
  }

  dateChange(e) {
    this.minDateToFinish.next(e.value.toString());
  }
  //-----------------End of Date picker implementation------------

  //------------------Testing-----------------------

  clearYearBuilt($event): void {
    this.term = "";
  }

  onChangePayee(eve) {
    let pOptionName = "OWNER";
    if (eve.value == pOptionName) {
      this.isDisabled = true;
      this.fetchData(true);
    }
    if (eve.value != pOptionName) {
      this.isDisabled = false;
      this.fetchData(false);
    }
  }

  fetchData(isDisabled) {
    if (isDisabled) {
      this.response?.SectionInfo[3]?.DetailedInfo[0]?.Attributes.find(
        (payeeElement) => {
          this.response?.SectionInfo[0]?.DetailedInfo[0]?.Attributes.find(
            (customerElement) => {
              (customerElement.Name == "Company Name" ||
                customerElement.Name == "Name") &&
              payeeElement.Name == "Payable To"
                ? (payeeElement.SelectedValue = customerElement.SelectedValue)
                : "";
              customerElement.Name == "Service Address" &&
              payeeElement.Name == "Address"
                ? (payeeElement.SelectedValue = customerElement.SelectedValue)
                : "";
              customerElement.Name == "City" && payeeElement.Name == "City"
                ? (payeeElement.SelectedValue = customerElement.SelectedValue)
                : "";
              customerElement.Name == "State" && payeeElement.Name == "State"
                ? (payeeElement.SelectedValue = customerElement.SelectedValue)
                : "";
              (customerElement.Name == "Zip Code" ||
                customerElement.Name == "Zip") &&
              payeeElement.AttributeId == "pZipcode"
                ? (payeeElement.SelectedValue = customerElement.SelectedValue)
                : "";
              payeeElement.AttributeId == "pAddress2" ? payeeElement.SelectedValue = "" : "";
            }
          );
        }
      );
    }
    if (!isDisabled) {
      this.response?.SectionInfo[3]?.DetailedInfo[0]?.Attributes.forEach(
        (element, i) => {
          if (i > 0) {
            element.SelectedValue = "";
          }
        }
      );
    }
  }

  payeeOnPageLoad() {
    if (
      this.response?.SectionInfo[3]?.DetailedInfo[0]?.Attributes[0]
        ?.SelectedValue == "OWNER"
    ) {
      this.isDisabled = true;
    }
  }

  // Comparison screen

  openComparisonDialog(workOrderResponse) {
    this.spinner.show();
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(ComparisonScreenDialogComponent, {
      data: {
        workOrderResponseData: workOrderResponse,
      },
      panelClass: className,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onSave(result?.data);
      }
    });
  }

  importSalesExcel(response) {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(UploadExcelComponent, {
      data: {
        caption: "Import Report",
        response: response,
      },
      panelClass: className,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loadDRWorkorder();
      this.loadDynamicMenu();
    });
  }
  
  actionWorkorder(WorkorderType) {
    let Obj = {
      WorkorderID: this.workorderID,
      StatusCode: WorkorderType == 'Deactivate' ? 920
        : WorkorderType == 'Reject' ? 250
          : 930,
      DialogTitle: WorkorderType == 'Deactivate' ? "Deactivate Workorder"
        : WorkorderType == 'Reject' ? "Reject Workorder"
          : "Cancel Workorder"
    };
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(CanceldeactivateDialogComponent, {
      data: {
        dataKey: Obj,
      },
      panelClass: className,
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.loadDRWorkorder();
      this.loadDynamicMenu();
      this.closeMeasureEditor();
    });
  }
}

export class SectionArray {
  sectionId: any;
  rowId: any;
}
